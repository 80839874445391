<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '创建'}移库入库单`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data">
      <en-form-item label="出库仓库">{{ form.data.stockTransferOut?.fromWarehouse?.name }}</en-form-item>
      <en-form-item label="入库仓库">{{ form.data.stockTransferOut?.toWarehouse?.name }} </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" type="textarea" :disabled="form.disabled"></en-input>
      </en-form-item>
      <en-form-item label="物流单">
        <upload-maintain :model-value="form.data.stockTransferOut?.imgUrls" multiple disabled></upload-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click" :disabled="form.disabled">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudInventoryDefinitions['StockTransferInDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          Object.assign(this.form.data, this.data)
          this.form.get()
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/transfer/in/:stockTransferInId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          update: {
            action: 'PUT /enocloud/stock/transfer/in',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A'
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form.update()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
